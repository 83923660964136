import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';


const MySwal = withReactContent(Swal);

function FileUpload({ buttonLabel, redirectPath, title, desc, multipleFile = false, endpoint, nombreArchivo }) {

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  const refdivisor = useRef('')
  const inputRefs = useRef([]);

  const navigate = useNavigate();


  const onDrop = (acceptedFiles) => {

    const selectedFile = acceptedFiles[0];


    if (selectedFile) {
      if (selectedFile.type === 'application/pdf') {
        setFile(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        setFileUrl(url);
        setPreview(selectedFile.name);
      } else {
        MySwal.fire({
          title: 'Invalid File Type',
          text: 'Please upload a valid PDF file.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });
  const handleButtonClick = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      let rangos = [];
      const divisoresDesde = document.querySelectorAll('input[name="divisorDesde[]"]');
      const divisoresHasta = document.querySelectorAll('input[name="divisorHasta[]"]');

      const valoresDesde = Array.from(divisoresDesde).map(input => input.value);
      const valoresHasta = Array.from(divisoresHasta).map(input => input.value);

      for (let i = 0; i < valoresDesde.length; i++) {
        rangos.push(`${valoresDesde[i]}-${valoresHasta[i]}`);
      }

      console.log(rangos);


      const params = new URLSearchParams();
      for (let i = 0; i < rangos.length; i++) {
        params.append('splits_ranges', rangos[i]);
      }


      MySwal.fire({
        title: 'Trabajando Data',
        text: 'Por Favor Espera hasta que la info sea procesada',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.post(`https://api.mydataworker.com/split-pdf/?${params.toString()}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          responseType: 'blob',

        });

        MySwal.close();

        // Crear un blob a partir del arraybuffer
        const blob = new Blob([response.data], { type: 'application/zip' });

        // Crear un enlace de descarga para el blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'archivos_divididos.zip'); // Nombre del archivo descargado
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);




        MySwal.fire({
          title: 'File Uploaded',
          text: 'Your file has been uploaded successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          // navigate(redirectPath);
        });
      } catch (error) {
        console.error(error);
        MySwal.close();
        MySwal.fire({
          title: 'Upload Failed',
          text: 'There was an error uploading your file. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

    } else {
      MySwal.fire({
        title: 'No File Selected',
        text: 'Please select a file to upload.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    // navigate(redirectPath);
  };

  const chageInput = (e) => {

    refdivisor.current = e.target.value
  }
  let contador = 2

  const AgregarInput = () => {


    const div = document.createElement('div');
    div.className = 'flex flex-row gap-2 items-center justify-center content-center';
    div.id = `divisor-${contador}`;



    const input = document.createElement('input');
    input.type = 'text';
    input.className = 'rounded-md h-8 w-20 px-4 ml-[49px]';
    input.id = 'desde-' + contador;
    input.defaultValue = refdivisor.current;
    input.name = `divisorDesde[]`; // Asignar un name único

    const input2 = document.createElement('input');
    input2.type = 'text';
    input2.className = 'rounded-md h-8 w-20 px-4';
    input2.id = 'hasta-' + contador;
    input2.defaultValue = refdivisor.current;
    input2.name = `divisorHasta[]`; // Asignar un name único

    // Crear una referencia para el input
    const inputRef = React.createRef();
    inputRefs.current.push(inputRef);

    // Asignar la referencia al input
    inputRef.current = input;

    input.addEventListener('change', () => {

    });

    const btnEliminar = document.createElement('button');
    btnEliminar.type = 'button';
    btnEliminar.className = 'bg-red-500 w-22 text-black px-4 py-2 rounded-lg hover:bg-[#E2E4E8] transition-colors';
    btnEliminar.textContent = 'X';
    btnEliminar.addEventListener('click', () => {
      div.remove();
    });

    div.appendChild(input);
    div.appendChild(input2);
    div.appendChild(btnEliminar);

    const container = document.getElementById('containerDIV');
    container.appendChild(div);

    contador++;
  };
  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex  justify-center  ">
      <div className="w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Dividir PDF en rango de páginas</h2>
        <p className="mb-4 ">
          {desc} Divide tu PDF indicando el rango de páginas que deseas para cada nuevo archivo.

          <br />

        </p>

        <div className='flex flex-col gap-2 py-2 text-black' id="containerDIV">

          <div className='flex flex-col gap-2 items-center ' name="divisor[1]">

            <div className="flex flex-row w-full gap-4 items-center justify-center font-semibold text-white  ">
              <label htmlFor="">Desde</label><label htmlFor="">Hasta</label>
            </div>
            <div className="flex flex-row w-full gap-2 items-center justify-center ">
              <input type="text" className='rounded-md h-8 w-20 px-4' id='desde-1' name='divisorDesde[]' defaultValue={refdivisor.current} onChange={chageInput} />
              <input type="text" className='rounded-md h-8 w-20 px-4' id='hasta-1' name='divisorHasta[]' defaultValue={refdivisor.current} onChange={chageInput} />

            </div>




          </div>


        </div>
        <div className='flex items-center justify-center w-full my-4'>
          <button
            className="bg-[#f7f7f7] w-36 text-black px-4 py-2 rounded-lg hover:bg-[#E2E4E8] transition-colors"
            type='button'
            onClick={AgregarInput} > Añadir Rango
          </button>
        </div>

        <div
          {...getRootProps()}
          className={`border-2 border-dashed border-gray-500 rounded-lg p-4 mb-4 text-center cursor-pointer ${isDragActive ? 'opacity-50' : ''
            }`}
          onClick={() => fileInputRef.current?.click()}
        >
          <input {...getInputProps()} ref={fileInputRef} accept='.pdf' />
          <p className="text-white">Arrastra y suelta tu archivo aquí, o haz clic para seleccionar un archivo</p>
        </div>
        {multipleFile && <button
          className="w-full bg-excelDarkGreen text-white py-2 rounded-lg mb-4 hover:bg-excelGreen transition-colors"
          onClick={() => fileInputRef.current?.click()}
        >

          Upload File
        </button>}

        <div className="bg-[#E2E4E8] p-4 mb-4 rounded-lg h-max">
          <h3 className="font-semibold mb-2 text-black">Vista previa</h3>
          <div className="h-[300px] bg-white rounded p-2">
            {fileUrl ? (
              <iframe
                src={fileUrl}
                className="w-full h-full"
                title="File Preview"
              />
            ) : (
              <p className="text-gray-500">El contenido de tu archivo aparecerá aquí luego de cargarlo.</p>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button
            className="bg-[#f7f7f7] text-black px-4 py-2 rounded-lg hover:bg-[#E2E4E8] transition-colors"
            onClick={handleButtonClick}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;