import React, { useState, useRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

import PdfViewer from '../components/Pdf-viewer';

const MySwal = withReactContent(Swal);

function FileUpload({ buttonLabel, redirectPath, title, desc, multipleFile = false, endpoint, nombreArchivo }) {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [fileUrl, setFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const urloffice = useRef(null)

  const onDrop = (acceptedFiles) => {




    if (acceptedFiles) {

      handleFileChange({ target: { files: acceptedFiles } });
      /* if (selectedFile.type === 'application/pdf') {
        // setFile(selectedFile);
        // const url = URL.createObjectURL(selectedFile);
        // setFileUrl(url);
        // let urlOf = url.replace('blob:', '')

        // urloffice.current = urlOf
        // setPreview(selectedFile.name);

      } else {
        MySwal.fire({
          title: 'Invalid File Type',
          text: 'Please upload a valid Pdf file.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } */
    }
  };
  const handleFileChange = useCallback((event) => {
    const files = event.target.files;

    if (files) {
      const newPdfFiles = Array.from(files).map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        file,
      }));
      setPdfFiles((prevFiles) => [...prevFiles, ...newPdfFiles]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  const handleButtonClick = async () => {
    if (pdfFiles) {
      const formData = new FormData();
      const params = new URLSearchParams();

      pdfFiles.forEach((pdfFile, index) => {
        params.append('order', index);
        formData.append('files', pdfFile.file, pdfFile.file.name);
      });
      // formData.append('order', JSON.stringify(order));




      MySwal.fire({
        title: 'Trabajando Data',
        text: 'Por Favor Espera hasta que la info sea procesada',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.post(`https://api.mydataworker.com/merge-pdfs/?${params.toString()}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },

          responseType: 'blob', // Importante para manejar la respuesta como un blob
        });

        MySwal.close();


        // Crear un enlace de descarga para el archivo HTML
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/html' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nombreArchivo + '.pdf'); // Nombre del archivo descargado
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        MySwal.fire({
          title: 'File Uploaded',
          text: 'Your file has been uploaded successfully!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          // navigate(redirectPath);
        });
      } catch (error) {
        console.error(error);
        MySwal.close();
        MySwal.fire({
          title: 'Upload Failed',
          text: 'There was an error uploading your file. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }

    } else {
      MySwal.fire({
        title: 'No File Selected',
        text: 'Please select a file to upload.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    // navigate(redirectPath);
  };

  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex  justify-center  ">
      <div className="w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Unir PDF gratis</h2>
        <p className="text-sm mb-4">
          {desc} Combina múltiples archivos PDF en un solo documento fácilmente. Ideal para fusionar informes, facturas, presentaciones y más.
          <br />

        </p>
        <div
          {...getRootProps()}
          className={`border-2 border-dashed border-gray-500 rounded-lg p-4 mb-4 text-center cursor-pointer ${isDragActive ? 'opacity-50' : ''
            }`}
          onClick={() => fileInputRef.current?.click()}
        >
          <input {...getInputProps()} ref={fileInputRef} accept='application/pdf' />
          <p className="text-white">Arrastra y suelta tu archivo aquí, o haz clic para seleccionar un archivo</p>
        </div>
        {multipleFile && <button
          className="w-full bg-excelDarkGreen text-white py-2 rounded-lg mb-4 hover:bg-excelGreen transition-colors"
          onClick={() => fileInputRef.current?.click()}
        >

          Upload File
        </button>}

        <div className="bg-[#E2E4E8] p-4 mb-4 rounded-lg h-max">
          <h3 className="font-semibold mb-2 text-black">Vista previa</h3>
          {/* <div className="h-[300px] bg-white rounded p-2">
            {fileUrl ? (
              <iframe
                src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(urloffice.current)}`}
                className="w-full h-full"
                title="File Preview"
              />
            ) : (
              <p className="text-gray-500">El contenido de tu archivo aparecerá aquí luego de cargarlo.</p>
            )}
          </div> */}
          <PdfViewer
            pdfFiles={pdfFiles}
            setPdfFiles={setPdfFiles}
            handleFileChange={handleFileChange}
          />
        </div>
        <div className="flex justify-center items-center">
          <button
            className="bg-[#f7f7f7] text-black px-4 py-2 rounded-lg hover:bg-[#E2E4E8] transition-colors"
            onClick={handleButtonClick}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;