import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

export default function LinkNAv({ ruta, titulo }) {
  const navigate = useNavigate();

  const handleClick = () => {

    navigate(ruta);
  };
  return (
    <li
      onClick={handleClick}
      className={`mb-4 px-2 py-4 cursor-pointer rounded-lg ${location.pathname === ruta ? 'bg-excelDarkGreen' : 'hover:bg-excelDarkGreen'}`}>
      <Link to={ruta} className="text-white">{titulo}</Link>
    </li>
  )
}
