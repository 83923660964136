// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, setPersistence, browserLocalPersistence, browserSessionPersistence, signOut } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBEINCq4nW1o1lhxJlQc7vYshcZjiDIsK4",
    authDomain: "my-data-worker.firebaseapp.com",
    projectId: "my-data-worker",
    storageBucket: "my-data-worker.appspot.com",
    messagingSenderId: "885209272663",
    appId: "1:885209272663:web:f4486676375dca44fd6e12"
  };

const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
  prompt : "select_account "
});

const microsoftProvider = new OAuthProvider('microsoft.com');

const auth = getAuth(app);

const signInWithGooglePopup = async (rememberMe) => {
  console.log(rememberMe)
  const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
  await setPersistence(auth, persistence);
  return signInWithPopup(auth, provider);
};

const signInWithMicrosoftPopup = async (rememberMe) => {
  const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
  await setPersistence(auth, persistence);
  return signInWithPopup(auth, microsoftProvider);
};

export { auth, signInWithGooglePopup , signInWithMicrosoftPopup};

