import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from 'lucide-react'
import { signInWithEmailAndPassword, onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth, signInWithGooglePopup, signInWithMicrosoftPopup } from './firebaseConfig';
import Swal from 'sweetalert2';


export default function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)

  const navigate = useNavigate();


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleRememberMe = (e) => {

    setRememberMe(e.target.checked)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Handle login logic here

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const token = await getIdToken(user);
      localStorage.setItem('jwtToken', token);
      navigate('/');

    } catch (error) {
      console.error(error);
      let errorMessage = 'An error occurred. Please try again. Check your email and password';
      Swal.fire({
        icon: 'error',
        title: 'Login Error',
        text: errorMessage,
      });
    }

  }

  const logGoogleUser = async () => {
    try {
      const result = await signInWithGooglePopup(rememberMe);
      const user = result.user;
      const token = await getIdToken(user);

      localStorage.setItem('jwtToken', token);
      navigate('/');
    } catch (error) {
      console.error('Error signing in with Google:', error);
      let errorMessage = 'An error occurred. Please try again. Check your email and password';

      Swal.fire({
        icon: 'error',
        title: 'Login Error',
        text: errorMessage,
      });
    }
  }

  const handleMicrosoftLogin = async () => {
    try {
      await signInWithMicrosoftPopup(rememberMe);
      navigate('/');
    } catch (error) {
      if (error.code !== 'auth/cancelled-popup-request') {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  return (
    <section className='m-2 md:m-8 flex gap-4'>
      <div className="min-h-screen  flex  justify-center w-full lg:w-3/5 mt-10 md:mt-24">
        <div className="bg-white p-8 rounded-lg w-full md:w-96 shadow-2xl h-max">
          <h1 className="text-4xl font-bold mb-6 text-gray-800 text-center">My data worker</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Correo
              </label>
              <input
                type="text"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6 relative">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                Contraseña
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className="absolute right-3 top-8 text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOffIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>

            <div className='flex flex-row items-center gap-2 mb-3'>

              <input type="checkbox" name="recuerdame" id="recuerdame"
                onChange={handleRememberMe}
                className='h-4 w-4 focus:ring-green-500 text-green-500 border-green-500 rounded'
              />
              <label htmlFor="recuerdame">Recuerdame</label>
            </div>
            <div className="flex items-center justify-center mb-6">

              <button
                type="submit"
                className="bg-green-500 text-white w-full px-4 py-2 rounded-md
               hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Iniciar sesión
              </button>
            </div>
            <div className="flex items-center justify-center mb-6">

              <button
                onClick={logGoogleUser}
                type="button"
                className=" flex flex-row gap-4 items-center justify-center bg-green-500 text-white w-full px-4 py-2 rounded-md
               hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Iniciar sesión con Google
                <div className='bg-white rounded-full w-8 h-8'>
                  <img src="/google.png" alt="" />
                </div>
              </button>
            </div>
            <div className="flex items-center justify-center mb-6">

              <button
                onClick={handleMicrosoftLogin}
                type="button"
                className=" flex flex-row gap-4 items-center justify-center bg-green-500 text-white w-full px-4 py-2 rounded-md
               hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                Sing up with Microsoft
                <div className='bg-white rounded-full w-8 h-8'>
                  <img src="/microsoft.png" alt="" className='p-0.5' />
                </div>
              </button>
            </div>
          </form>

          <div className="relative flex items-center mb-6">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="mx-2 text-gray-500">O</span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>

          <a
            href='/register'

            className="w-full flex items-center justify-center bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          >
            Crear cuenta
          </a>


        </div>
      </div>
      <div className="w-2/5 h-screen hidden lg:block  ">
        <img src="/logincompresed.png" alt="" className='h-full  object-contain rounded-3xl shadow-2xl' />
      </div>
    </section>
  )
}